.rsvp-area {
    background: linear-gradient(rgba(100, 158, 147, 0.50), rgba(100, 158, 147, 0.50)), transparent url(./gemma_mati_1.jpg) center center/cover no-repeat fixed;
    /*background: linear-gradient(rgba(100, 158, 147, 0.50), rgba(100, 158, 147, 0.50));*/
    padding: 100px 0;
    position: relative;
    background-attachment: fixed;
    z-index: 1;
}
.rsvp-wrap {
    background: rgba(255, 255, 255, .9);
    padding: 70px;
}
.rsvp-wrap .section-title h2{
    margin-bottom: 0;
    padding-bottom: 0px;
}

.rsvp-wrap .section-title h2:before{
   display: none;
}
.rsvp-wrap .section-title{
    margin-bottom: 30px;
}
.rsvp .contact-form input {
    width: 100%;
    height: 45px;
    margin-top: 0px;
    padding-left: 10px;
    background: #fff;
    opacity: 1;
    border: none;
    border-radius: 5px;
}

.rsvp .form-control {
    margin-top: 0px;
    font-size: 16px;
    background: #fff;
}

.rsvp select.form-control:not([size]):not([multiple]) {
    height: 50px;
    border-radius: 5px;
    color: #666;
    font-size: 14px;
    -webkit-appearance:none;
    -ms-appearance:none;
    -o-appearance:none;
    appearance:none;
    -moz-appearance: none;
    background:#fff url(../../images/select-icon.png) no-repeat calc(100% - 15px) center;
    position: relative;
    border: none;

}
.contact-textarea {
    width: 100%;
    height: 170px;
    margin-top: 20px;
    padding-left: 10px;
    padding-top: 10px;
    border: none;
    border-radius: 5px;
}

.rsvp .contact-form p{
    color: red;
    font-size: 14px;
}
.rsvp .section-title p {
    padding-top: 10px;
    color:#5a5858;
}

.rsvp .contact-form button {
    background: none;
    border: none;
    padding: 5px 15px 8px;
    background: #649e93;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}
.rsvp .contact-form label {
    padding: 0;
    margin: 0;
    margin-left: 5px;
    font-weight: 100;
    font-size: 14px;
    color: #888;
}

.rsvp .contact-form button:hover {
    background: #3f7267;
}

.rsvp .contact-form  button.decline {
    background: #d14e44;
}
.rsvp .contact-form button:hover.decline{
    background: #91362f;
}

.rsvp .contact-form .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */

    color: #666;
    font-size: 14px;
}
::-moz-placeholder {
    /* Firefox 19+ */

    color: #666;
    font-size: 14px;
}
:-ms-input-placeholder {
    /* IE 10+ */

    color: #666;
    font-size: 14px;
}
:-moz-placeholder {
    /* Firefox 18- */

    color: #666;
    font-size: 14px;
}
.rsvp .disclaimers p {
    padding: 0;
    margin: 0;
    margin-left: 5px;
    font-weight: 100;
    font-size: 14px;
    color: #888;
}