.section-title h2 {
    position: relative;
    font-size: 50px;
    font-family: 'Great Vibes', cursive;
    margin-bottom: 50px;
    color: #649e93;
    padding-bottom: 30px;
}

.section-title h2:before {
    position: absolute;
    content: "";
    background: url(../../images/section-title.png);
    width: 20%;
    height: 89%;
    bottom: -16px;
    left: 41%;
}
.section-title{
    text-align: center;
}

@media(max-width:767px){
    .section-title h2 {
        margin-bottom: 30px;
        font-size: 30px;
    }
}