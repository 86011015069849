.login input {
    width: 100%;
    height: 45px;
    margin-top: 0px;
    padding-left: 10px;
    background: #fff;
    opacity: 1;
    border: none;
    border-radius: 5px;
}

.login {
	padding-top: 10vh;
}

.login  button {
    background: none;
    border: none;
    padding: 5px 15px 8px;
    background: #649e93;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
}
.login .error {
	margin-top: 20px;
    color: #f00;
}