
.logo h2 {
    font-size: 24px;
    font-family: 'Great Vibes', cursive;
    color:#fff;
}

.logo h2 span {
    color: #fff;
    padding: 0 10px;
}

.footer-area {
  /*background: url(../../images/footer/1.jpg) no-repeat center center / cover;*/
  background: linear-gradient(rgba(100, 158, 147, 0.50), rgba(100, 158, 147, 0.50));
  padding: 100px 0;
  position: relative;
  z-index: 1;
  display: flex;
  min-height: 100vh;
  align-items: center;
  align-content: flex-start;
}

.footer-area:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*background: #000;*/
  opacity: .5;
  z-index: -1;
}

.footer-content {
  margin: auto;
  text-align: center;
}

.content-sub {
  padding: 50px 80px;
  /*background: rgba(94, 154, 142, .5);*/
  background: linear-gradient(rgba(100, 158, 147, 0.50), rgba(100, 158, 147, 0.50));
  display: inline-block;
  z-index: 99;
  position: relative;
}

.content-sub:before {
  position: absolute;
  content: "";
  left: -8px;
  top: -6px;
  width: 103%;
  height: 105%;
  border: 1px solid rgba(255,255,255, 0.4);
}

.content-sub h2 {
  font-size: 80px;
  font-family: 'Great Vibes', cursive;
  color: #fff;
}

.content-sub span {
  font-size: 30px;
  color: #dffff9;
}
