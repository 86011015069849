.GL_root {
  /*background-color: #00ff00;*/
  min-height: 100px;
  padding-top: 150px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: column;
  font-family: Muli,sans-serif;
  margin: 0 25px 0 25px;
  color:  #5a5858;
}
.GL_root input {
  color:  #5a5858;

}
.GL_table {
  overflow-x: auto;
  border-collapse: collapse;
  margin: 25px 0;
  background-color: rgba(255, 255, 255, .9);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.GL_table input {
    background: none;
    border: none;
    font-weight: lighter;
}
.GL_table thead {
  border: 1px solid black;
}
.GL_table thead tr {
    background-color: #333;
    color: #ffffff;
    text-align: left;
}
.GL_table th {
  border: 1px solid black;
  padding: 10px;
  font-weight: normal;
}
.GL_table td {
  /*border: 1px solid black;*/
  text-align: center;
  padding: 10px 15px;
}
.GL_table tbody tr {
  background-color: #6da499;
  border-bottom: 1px solid #dddddd;
  background-color: rgba(255, 255, 255, .1);

}
.GL_table tbody tr:nth-of-type(odd) {
  /*background-color: #5e9a8d;*/
  background-color: rgba(255, 255, 255, .9);
}

.GL_table tbody .fa-thumbs-down, 
.GL_table tbody .fa-times-circle {
    color: #e53935;
}
.GL_table tbody .fa-thumbs-up,
.GL_table tbody .fa-check-circle {
    color: #8bc34a;
}
.GL_header [type=number]{
  width: 30px;
}

.GL_header [type=button]{
  background: none;
  border: none;
  padding: 5px 15px 8px;
  background: #333;
  color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .GL_root {
    flex-wrap: wrap;
  }
  .GL_table td {
    text-align: left;
  }
}
