.GL_root {
    /*background-color: #00ff00;*/
    min-height: 100px;
    padding-top: 150px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
    font-family: Muli,sans-serif;
    margin: 0 25px 0 25px;
    color:  #5a5858;
}

.GL_root .cards {
    display: flex;
    flex-direction: row;
}
.GL_root .cards .card {
    margin-bottom: 5px;
}

.card-singlenumber .card-text {
    font-weight: bold;
    font-size: 2rem;
}
